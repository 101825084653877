<section class="home-banner">
	<main class="home-banner__main theme-text-shadow">
    <div class="home-banner__avatar">
      <img src="./assets/images/avatar.webp" [alt]="data?.others.title || 'Sébastien CAYET'"/>
    </div>
    <h1>{{ data?.others.title }}</h1>
    <div class="d-flex pb-3">
      <span class="icon-star mx-1"></span>
      <span class="icon-star mx-1"></span>
      <span class="icon-star mx-1"></span>
      <span class="icon-star mx-1"></span>
      <span class="icon-star mx-1"></span>
    </div>
    <p>{{ data?.others.subtitle }}</p>
  </main>
  <a class="home-banner__more theme-text-shadow" href="#role-section">
    <span>{{ data?.others.more }}</span>
    <div class="home-banner__more-icon">
      <span class="icon-arrow-down"></span>
    </div>
  </a>
</section>
