export const environment = {
  production: false,
  appUrl: 'localhost:4200',
  firebaseConfig: {
    apiKey: 'AIzaSyA-l5N8CY5N90XwNVIJrYTilaXpihcRZL0',
    authDomain: 'my-website-9d20f.firebaseapp.com',
    projectId: 'my-website-9d20f',
    storageBucket: 'my-website-9d20f.appspot.com',
    messagingSenderId: '274059150194',
    appId: '1:274059150194:web:2623c0396e5afb407553eb',
    measurementId: 'G-P0LLMN34X7',
  },
};
