<header class="header">
  <div class="header-left">
    <a class="header-side-button site-icon-button d-none d-md-inline"
      [routerLink]="routes.app.home"
      routerLinkActive="active">
      <img class="site-icon-button-image" [src]="images.siteMainImage" alt="SC"/>
    </a>
    <span class="header-title d-none d-md-inline">{{ data?.others.title }}</span>
    <button class="header-side-button d-md-none"
      id="dropdownMenuButton"
      type="button" 
      data-toggle="dropdown">
      <app-icon icon="icon-ellipsis-v-solid"></app-icon>
    </button>
    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
      <ng-container *ngFor="let btn of navigationButton">
        <a class="dropdown-item"
          [routerLink]="btn.route">
          <app-icon class="mr-2" [icon]="btn.iconName"></app-icon>{{ btn.title }}
        </a>
      </ng-container>
    </div>
  </div>
  <div class="header-middle">
    <ng-container *ngFor="let btn of navigationButton">
      <a class="header-main-button d-none d-md-inline"
        [class.selected]="currentRoute === btn.route"
        [routerLink]="btn.route"
        routerLinkActive="active">
        <app-icon [icon]="btn.iconName"></app-icon>
      </a>
    </ng-container>
    <span class="header-title d-md-none">{{ data?.others.title }}</span>
  </div>
  <div class="header-right">
    <span class="btn-group">
      <button class="header-side-button dropdown-toggle locale-button"
        id="dropdownLanguageButton"
        type="button" 
        data-toggle="dropdown">
        <img class="locale-button-image"
          [src]="getCurrentLocale().image"
          [alt]="getCurrentLocale().id.toUpperCase()"/>
        <div class="locale-button-text">{{getCurrentLocale().id.toUpperCase()}}</div>
        <div class="locale-button-arrow-down"></div>
      </button>
      <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownLanguageButton">
        <ng-container *ngFor="let locale of getAvailableLocales()">
          <button class="dropdown-item" (click)="setLocale(locale.id)">{{locale.name}}</button>
        </ng-container>
      </div>
    </span>
  </div>
</header>