<app-header></app-header>

<!-- Page header -->
<section class="page-header gradient-animation d-md-none">
  <h1 class="text-center">{{ 'Envoyer un mail' | translate:'sendMail' }}</h1>
</section>

<!-- Page content -->
<div class="container-fluid">
  <div class="row">
    <!-- Personal data section -->
    <section class="page-header gradient-animation col-md-5">
      <article class="contact-info-wrapper d-none d-md-block">
        <div class="d-flex">
          <ul class="list-unstyled">
            <li class="contact-info iconed-text">
              <app-icon icon="icon-user-solid"></app-icon>
              <span>{{ 'Sébastien CAYET' | translate:'title' }}</span>
            </li>
          </ul>
          <div class="contact-info-social">
            <a [href]="routes.external.facebook" class="button icon-button bouncy-1" target="_blank">
              <app-icon icon="icon-facebook-brands"></app-icon>
            </a>
            <a [href]="routes.external.linkedIn" class="button icon-button bouncy-2" target="_blank">
              <app-icon icon="icon-linkedin-brands"></app-icon>
            </a>
            <a [href]="routes.external.gitHub" class="button icon-button bouncy-3" target="_blank">
              <app-icon icon="icon-github-brands"></app-icon>
            </a>
          </div>
        </div>
        <div class="text-center">
          <a [href]="routes.files.resume" class="button bouncy-0" target="_blank">
            {{ 'Télécharger mon CV' | translate:'seeResume' }}
          </a>
        </div>
      </article>
    </section>

    <!-- Form section -->
    <section class="page-section col-md-7">
      <h1 class="text-center iconed-text d-none d-md-block">
        <app-icon icon="icon-envelope-open-text-solid"></app-icon>
        <span>{{ 'Envoyer un mail' | translate:'sendMail' }}</span>
      </h1>
      <form #contactFormElement [formGroup]="contactForm" class="container"
        action="https://formspree.io/{{routes.mailAddress}}" method="POST" target="_blank">
        <p class="text-center">{{ "Remplissez le formulaire ci-dessous pour m'envoyer un mail." | translate:'mailFormText' }}</p>
        <fieldset class="form-group"
          [class.invalid]="isFieldInvalid('firstName')">
          <div class="row">
            <label class="col-12 col-md-2 col-lg-3"
              for="mailFirstName">
              {{ 'Prénom' | translate:'firstName' }}
            </label>
            <input class="col-12 col-md-10 col-lg-9 form-control"
              name="firstName"
              formControlName="firstName"
              type="text"
              id="mailFirstName">
          </div>
          <small class="form-error-text">
            {{ 'Ce champ est obligatoire.' | translate:'mailFormMandatoryErrorMessage' }}
          </small>
        </fieldset>
        <fieldset class="form-group"
          [class.invalid]="isFieldInvalid('lastName')">
          <div class="row">
            <label class="col-12 col-md-2 col-lg-3 col-form-label"
              for="mailLastName">
              {{ 'Nom' | translate:'lastName' }}
            </label>
            <input class="col-12 col-md-10 col-lg-9 form-control"
              name="lastName"
              formControlName="lastName"
              type="text"
              id="mailLastName">
          </div>
          <small class="form-error-text">
            {{ 'Ce champ est obligatoire.' | translate:'mailFormMandatoryErrorMessage' }}
          </small>
        </fieldset>
        <fieldset class="form-group"
          [class.invalid]="isFieldInvalid('email')">
          <div class="row">
            <label class="col-12 col-md-2 col-lg-3 col-form-label"
              for="mailEmail">
              {{ 'E-mail' | translate:'email' }}
            </label>
            <input class="col-12 col-md-10 col-lg-9 form-control"
              name="email"
              formControlName="email"
              type="email"
              id="mailEmail">
          </div>
          <small class="form-error-text">
            {{ 'Entrez une adresse mail valide.' | translate:'mailFormEmailErrorMessage' }}
          </small>
        </fieldset>
        <fieldset class="form-group"
          [class.invalid]="isFieldInvalid('object')">
          <div class="row">
            <label class="col-12 col-md-2 col-lg-3 col-form-label"
              for="mailObject">
              {{ 'Objet' | translate:'subject' }}
            </label>
            <input class="col-12 col-md-10 col-lg-9 form-control"
              name="object"
              formControlName="object"
              type="text"
              id="mailObject">
          </div>
          <small class="form-error-text">
            {{ 'Ce champ est obligatoire.' | translate:'mailFormMandatoryErrorMessage' }}
          </small>
        </fieldset>
        <fieldset class="form-group"
          [class.invalid]="isFieldInvalid('message')">
          <div class="row">
            <label class="col-12 col-md-2 col-lg-3 col-form-label"
              for="mailMessage">
              {{ 'Message' | translate:'message' }}
            </label>
            <textarea class="col-12 col-md-10 col-lg-9 form-control"
              name="message"
              formControlName="message"
              rows="5"
              id="mailMessage"></textarea>
          </div>
          <small class="form-error-text">
            {{ 'Ce champ est obligatoire.' | translate:'mailFormMandatoryErrorMessage' }}
          </small>
        </fieldset>
        <p class="text-center" *ngIf="isFormInvalid()">
          {{ "Ce formulaire n'a pas être envoyé parce qu'au moins un des champs est invalide." | translate:'mailFormErrorMessage' }}
        </p>
        <div class="d-flex justify-content-center">
          <button class="button" type="button" (click)="sendEmail()">
            {{ 'Envoyer' | translate:'send' }}
          </button>
        </div>
      </form>
    </section>
  </div>
</div>

<!-- Contact Section -->
<app-contact-panel class="d-md-none"></app-contact-panel>
