<footer class="footer iconed-text py-5">
    <div class="footer__border"></div>
    <div class="footer__avatar">
        <img src="./assets/images/avatar.webp" [alt]="data?.others.title || 'Sébastien CAYET'"/>
    </div>
    <p class="text-center">
        <strong>{{ data?.others.title }}</strong>
    </p>
    <ul class="list-unstyled d-flex justify-content-center">
        <li class="mx-2">
            <a class="role-icon dark-mode interactable-box" [href]="ROUTES.external.twitter"
                target="_blank"
                aria-label="Visit X">
                <span class="icon-x-twitter"></span>
            </a>
        </li>
        <li class="mx-2">
            <a class="role-icon dark-mode interactable-box" [href]="ROUTES.external.linkedIn"
                target="_blank"
                aria-label="Visit LinkedIn">
                <span class="icon-linkedin-brands"></span>
            </a>
        </li>
        <li class="mx-2">
            <a class="role-icon dark-mode interactable-box" [href]="ROUTES.external.medium"
                target="_blank"
                aria-label="Visit Medium">
                <span class="icon-medium"></span>
            </a>
        </li>
        <li class="mx-2">
            <a class="role-icon dark-mode interactable-box" [href]="ROUTES.external.gitHub"
                target="_blank"
                aria-label="Visit GitHub">
                <span class="icon-github-brands"></span>
            </a>
        </li>
        <li class="mx-2">
            <a class="role-icon dark-mode interactable-box" [href]="ROUTES.external.itch"
                target="_blank"
                aria-label="Visit Itch.io">
                <span class="icon-itch-io"></span>
            </a>
        </li>
    </ul>
    <p class="text-center">
        {{ data?.others.footerCopy }} {{ year }}
    </p>
    <p class="text-center">
        {{ data?.others.footerMadeWith }}
    </p>
    <div class="d-flex justify-content-center align-items-center">
        <button class="mx-auto btn dark-mode interactable-box" (click)="scrollToTop()">
            <span class="icon-arrow-up"></span>
            <span class="mx-1">{{ data?.others.footerAction }}</span>
            <span class="icon-arrow-up"></span>
        </button>
    </div>
</footer>
