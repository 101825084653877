<header class="header">
    <div class="header__title">
        <a class="header-side-button site-icon-button d-none d-md-inline"
            [routerLink]="routes.app.home"
            routerLinkActive="active">
            <img class="header__title-icon header__icon" [src]="images.siteMainImage" alt="SC"/>
        </a>
        <h1 class="header__title-text theme-text-shadow">Sébastien CAYET</h1>
    </div>
    <ul class="header__side-menu">
        <li>
            <button class="header__language inline p-0" data-toggle="dropdown">
                <img class="header__icon"
                    [src]="getCurrentLocale().image"
                    [alt]="getCurrentLocale().id.toUpperCase()"/>
            </button>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownLanguageButton">
                <ng-container *ngFor="let locale of getAvailableLocales()">
                    <button class="dropdown-item" (click)="setLocale(locale.id)">{{locale.name}}</button>
                </ng-container>
            </div>
        </li>
        <li class="theme-text-shadow mt-1">
            <a [href]="routes.external.twitter" target="_blank" aria-label="Visit X">
                <span class="icon-x-twitter"></span>
            </a>
        </li>
        <li class="theme-text-shadow mt-1">
            <a [href]="routes.external.linkedIn" target="_blank" aria-label="Visit LinkedIn">
                <span class="icon-linkedin-brands"></span>
            </a>
        </li>
        <li class="theme-text-shadow mt-1">
            <a [href]="routes.external.medium" target="_blank" aria-label="Visit Medium">
                <span class="icon-medium"></span>
            </a>
        </li>
        <li class="theme-text-shadow mt-1">
            <a [href]="routes.external.gitHub" target="_blank" aria-label="Visit GitHub">
                <span class="icon-github-brands"></span>
            </a>
        </li>
        <li class="theme-text-shadow mt-1">
            <a [href]="routes.external.itch" target="_blank" aria-label="Visit Itch.io">
                <span class="icon-itch-io"></span>
            </a>
        </li>
    </ul>
</header>
